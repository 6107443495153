import Vue from 'vue'
import Router from 'vue-router'

// Page content
import Home from '@/components/Home'

// Fallback page
import PageNotFound from '@/components/PageNotFound'

Vue.use(Router)

export default new Router({
    mode: 'history',
    routes: [{
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '**',
        name: 'PageNotFound',
        component: PageNotFound
    }]
})