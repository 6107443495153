import axios from 'axios'
// import config from '@/config/config'

export default {
    get_login_status(callback)
    {
        var csrf_token = document.head.querySelector('meta[name="csrf-token"]').content;
        var token1 = csrf_token.substr(0, 10);
        var token2 = csrf_token.substr(10);
    
        var date = ("0" + new Date().getDate()).slice(-2);
        var month = ("0" + (new Date().getMonth() + 1)).slice(-2);
        var year = new Date().getFullYear().toString().substr(2);
        
        var api_url = '/login/status'
      
        return axios.get(api_url,{
            headers: {
                'Authorization': 'Bearer '+ token1 + month + date + year + token2,
            },
        }).then(
            function(rs){callback(rs) 
        }).catch(
            function(e){ console.log(["error",e]) 
        });
        
    },
    get_count_notification(callback)
    {
        var api_url = '/api/get-notification-count/'

        return axios.get(api_url
        ).then(
            function(rs){callback(rs) 
        }).catch(
            function(e){ console.log(["error",e]) 
        });
    },
    get_session_trigger_notification_popup(callback)
    {
        var api_url = '/api/get-session-trigger-notification-popup/'

        return axios.get(api_url
        ).then(
            function(rs){callback(rs) 
        }).catch(
            function(e){ console.log(["error",e]) 
        });
    },
    forget_session_trigger_notification_popup(callback)
    {
        var api_url = '/api/forget-session-trigger-notification-popup/'

        return axios.get(api_url
        ).then(
            function(rs){callback(rs) 
        }).catch(
            function(e){ console.log(["error",e]) 
        });
    },
    get_session_trigger_city_popup(callback)
    {
        var api_url = '/api/get-session-trigger-city-popup/'

        return axios.get(api_url
        ).then(
            function(rs){callback(rs) 
        }).catch(
            function(e){ console.log(["error",e]) 
        });
    },
    get_user_agent(callback)
    {
        var api_url = '/api/get-user-agent/'

        return axios.get(api_url
        ).then(
            function(rs){callback(rs) 
        }).catch(
            function(e){ console.log(["error",e]) 
        }); 
    }
}