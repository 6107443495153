import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import router from './router'
import { global_function } from '@/global_function.js'
import VueGtm from 'vue-gtm';
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

Vue.config.productionTip = false
Vue.use(Vuex);

// Sentry.init({
//     Vue,
//     dsn: "https://3c405c8b758f499aaabed9182b2cdb69@o1241274.ingest.sentry.io/6394932",
//     integrations: [
//       new BrowserTracing({
//         routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//         tracingOrigins: ["liputan6id.test", "liputan6.id", "merdeka.com", "liputan6.com", "kapanlagi.com", /^\//],
//       }),
//     ],
//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 1.0,
// });

Vue.filter('formatPublishedAt', function(value) {
    if (value) {
        value = value.replace(/-/g, '/');
        var publish_at = new Date(value);

        if (Object.prototype.toString.call(publish_at) === "[object Date]") {
            if (isNaN(publish_at.getTime())) {
                // date is not valid
                var publish_at = new Date();
            }
        }

        var now = new Date();
        var diff =(now.getTime() - publish_at.getTime()) / 1000;
        diff /= 60;
        diff = Math.abs(Math.round(diff));
        
        const month_name = ["Januari", "Februari", "Maret","April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];
        var rs_published_at = publish_at.getDate() + " " + month_name[publish_at.getMonth()] + " " + publish_at.getFullYear()  + " " + publish_at.getHours() + ":" + publish_at.getMinutes();
        
        var minutes = diff;
        var hours = Math.floor( (minutes / 60) );
        var days = Math.floor( (hours / 24) );
        var months = Math.floor( (days / 30) );

        if(months >= 1)
        {
            rs_published_at = months + ' bulan yang lalu';
        }
        else if(days >= 1)
        {
            rs_published_at = days +' hari yang lalu';
        }
        else if(hours >= 1)
        {
            rs_published_at = hours +' jam yang lalu';
        }
        else if(minutes >= 1)
        {
            rs_published_at = minutes +' menit yang lalu';
        }

        return rs_published_at;
    }
})

Vue.filter('formatDate', function(value) {
    if (value) {
        value = value.replace(/-/g, '/');
        var publish_at = new Date(value);

        if (Object.prototype.toString.call(publish_at) === "[object Date]") {
            if (isNaN(publish_at.getTime())) {
                // date is not valid
                publish_at = new Date();
            }
        }

        const months = ["Januari", "Februari", "Maret","April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];
        const day = ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jum'at", "Sabtu"];
        var hours = (publish_at.getHours()<10?'0':'') +publish_at.getHours();
        var minutes = (publish_at.getMinutes()<10?'0':'') +publish_at.getMinutes();

        var rs_published_at = publish_at.getDate() + " " + months[publish_at.getMonth()] + " " + publish_at.getFullYear() + ", " + hours + ":" + minutes + " WIB";
        return rs_published_at;
    }
})

Vue.component('loading',{
    template:'<div class="white-widget grey-bg author-area"> <div class="auth-info row"> <div class="timeline-wrapper"> <div class="timeline-item"> <div class="animated-background"> <div class="background-masker header-top"></div><div class="background-masker header-left"></div><div class="background-masker header-right"></div><div class="background-masker header-bottom"></div><div class="background-masker subheader-left"></div><div class="background-masker subheader-right"></div><div class="background-masker subheader-bottom"></div></div></div></div></div></div>'
});

Vue.prototype.$loadingRouteData = true;

Vue.use(VueGtm, {
    id: 'GTM-PM5N6SJ', // Your GTM single container ID or array of container ids ['GTM-xxxxxxx', 'GTM-yyyyyyy']
    // queryParams: { // Add url query string when load gtm.js with GTM ID (optional)
    //   gtm_auth:'AB7cDEf3GHIjkl-MnOP8qr',
    //   gtm_preview:'env-4',
    //   gtm_cookies_win:'x'
    // },
    defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
    enabled: false, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
    debug: false, // Whether or not display console logs debugs (optional)
    loadScript: false, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional) 
    vueRouter: router, // Pass the router instance to automatically sync with router (optional)
    ignoredViews: ['Home'], // If router, you can exclude some routes name (case insensitive) (optional)
    trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
  });
  
const store = new Vuex.Store({
    state: {
        auth: {
            login: 0
        }
    }
});
    
export const vm = new Vue({
    el: '#app',
    mixins: [global_function],
    router,
    store,
    components: { App },
    template: '<App/>',
    watch: {
        $route (to, from){
            googletag.pubads().refresh(); //DFP
            // loadComscore();
            // loadAlexa();
        },
    }
})