<template>
  <div>
    <!--header-->
    <header class="header fixed top-0 inset-x-0 z-20">
        <div class="container max-w-screen-md mx-auto px-4">
            <div class="header-body flex items-center justify-between h-20 gap-4">
                <div class="header-item"><a class="header-logo inline-block" href="/"><img class="object-contain" :src="base_url+('/assets/images/youniverse/youniverse.png')" width="140" height="60"></a></div>
                <div class="header-item">
                    <div class="header-btn relative w-50 h-50 px-4 -mr-4 z-20 text-right lg:hidden">
                        <div style="display: inline-flex">
                            <div class="notif-mobile" style="display: none"></div>
                            <button type="button" @click="menu = !menu" name="btn-menubar" aria-label="btn-menubar">
                                <div class="hamburger hamburger--squeeze" :class="[menu ? 'is-active' : '']">
                                    <div class="hamburger-inner"></div>
                                </div>
                            </button>
                        </div>
                    </div>
                    <div class="header-collapse header-collapse--menu fixed inset-0 overflow-y-auto bg-cream z-10 lg:static" :class="[menu ? 'open' : '']">
                        <div class="header-collapse-inner flex items-center min-h-full py-20 px-4 lg:p-0">
                            <div class="header-collapse-body flex-1">
                                <ul class="nav flex flex-col list-none text-center text-xl md:text-sm whitespace-nowrap lg:flex-row md:items-center md:gap-4 my-8 md:my-0">
                                    <li class="nav-item border-b border-yellow last:border-b-0 lg:border-b-0"><a href="#what" data-toggle="menuspy" class="nav-item-link font-made block py-4 px-2">What is It?</a></li>
                                    <li class="nav-item border-b border-yellow last:border-b-0 lg:border-b-0 "><a href="#get" data-toggle="menuspy" class="nav-item-link font-made block py-4 px-2">What You Get</a></li>
                                    <li class="nav-item border-b border-yellow last:border-b-0 lg:border-b-0"><a href="/room" class="nav-item-link font-made block py-4 px-2">Community</a></li>
                                    <li class="nav-item border-b border-yellow last:border-b-0 lg:border-b-0"><a href="/event"  class="nav-item-link font-made block py-4 px-2">Events</a></li>
                                    <li class="nav-item btn-login-youniverse responsive-desktop"><a style="line-height: 5px" href="/login"  class="nav-item-link font-made block py-4 px-2">Login</a></li>
                                    <li class="nav-item border-b border-yellow last:border-b-0 lg:border-b-0 responsive-desktop notif-desktop" style="display: none"></li>
                                    <li class="nav-item nav-login responsive-desktop mx-auto" style="display: none;"><a href="" class="login"><img src="" class="icon icon-user"></a></li>
                                </ul>
                                <div></div>
                                <a href="/login" class="btn-login-mobile btn btn--primary text-center text-xl bg-primary text-white block max-w-xs mx-auto my-4 py-4 px-6 lg:hidden lg:px-12 hover:bg-primary-dark font-semibold transition wow  animate__fadeIn animate__animated" data-wow-delay="1.4s" style="visibility: visible; animation-delay: 1.4s; animation-name: fadeIn;">Login</a>
                                <a href="/dashboard" class="btn-dashboard-mobile btn btn--secondary text-center text-xl bg-secondary text-white block max-w-xs mx-auto my-4 py-4 px-6 lg:hidden lg:px-12 hover:bg-secondary-dark font-semibold transition wow  animate__fadeIn animate__animated" data-wow-delay="1.4s" style="visibility: visible; animation-delay: 1.4s; animation-name: fadeIn;">Akun Saya</a>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>

    <!--main-->
    <main class="main" role="main">
        <div class="main-body">

            <!--section.home-->
            <section id="home" class="section section--home text-white relative" style="overflow-x: hidden">
                <div class="section-main w-full">
                    <div class="container bg-cream max-w-screen-md mx-auto py-20 px-4 flex flex-col items-center justify-center text-center">
                        <div class="section-body">
                            <div class="md:text-left md:flex md:items-center md:justify-start md:flex-wrap md:flex-row-reverse">
                                <img :src="base_url+('/assets/images/youniverse/homepage/hero-new.png')" class="object-contain ml-5 md:flex-1 wow  animate__fadeInUp animate__animated" data-wow-delay="1s" alt="headline" width="630" height="112" style="visibility: visible; animation-delay: 1s; animation-name: fadeInUp;"> 
                                <span class="md:text-left md:flex-1 ">
                                    <h1 class="section--home-title md:text-left text-4xl font-medium font-made mb-6 xl:tracking-widest leading-tight"><small class="block md:text-left text-lg font-bold mt-4 xl:text-4xl xl:tracking-wider font-frank wow  animate__fadeInUp animate__animated" data-wow-delay="1.2s" style="visibility: visible; animation-delay: 1.2s; animation-name: fadeInUp;">Temukan teman komunitas dengan minat yang sama</small></h1>
                                    <p class="section--home-caption md:text-left mb-6 wow  animate__fadeInUp animate__animated" data-wow-delay="1.4s" style="visibility: visible; animation-delay: 1.4s; animation-name: fadeInUp;">Dapatkan undangan acara eksklusif, dan menangkan hadiah kejutan yang dibagikan setiap minggunya!</p>
                                    <p class="section--home-caption md:text-left mb-6 wow  animate__fadeInUp animate__animated" data-wow-delay="1.8s" style="visibility: visible; animation-delay: 1.4s; animation-name: fadeInUp;">
                                        Kamu bisa daftar sebagai member dan sebagai komunitas. Sekarang kita hadir di aplikasi. Download sekarang!
                                    </p>
                                </span>
                            </div>
                            <div class="inline-block md:flex justify-center md:justify-start gap-4">
                                <!-- <a href="/community" class="btn btn--primary bg-primary text-white block max-w-xs mx-auto md:mx-0 my-4 py-4 px-6 lg:px-12 hover:bg-primary-dark font-semibold transition wow  animate__fadeIn animate__animated" data-member='top' data-wow-delay="1.4s" style="visibility: visible; animation-delay: 1.4s; animation-name: fadeIn;">Daftar sebagai Member</a> -->
                                <!-- <a href="https://bit.ly/3RLIHB8" data-comm='top' class="btn btn--secondary bg-secondary text-white block max-w-xs mx-auto md:mx-0 my-4 py-4 px-6 lg:px-12 hover:bg-secondary-dark font-semibold transition wow  animate__fadeIn animate__animated" data-wow-delay="1.4s" style="visibility: visible; animation-delay: 1.4s; animation-name: fadeIn;">Daftar sebagai Komunitas</a> -->

                                <a href="https://apps.apple.com/app/apple-store/id6451060380?pt=117963666&ct=websiteyouniverse_awareness_USP_youniverse&mt=8" target="_blank">
                                    <img :src="base_url+('/assets/images/youniverse/download-appstore.png')" class="wow  animate__fadeInUp animate__animated pb-4 md:pb:0" data-wow-delay="2s" alt="" width="" height="" style="visibility: visible; animation-delay: 1s; animation-name: fadeInUp;"> 
                                </a>
                                <a href="https://play.google.com/store/apps/details?id=com.kly.youniverse&referrer=utm_source%3Dwebsiteyouniverse%26utm_campaign%3Dawareness_USP_youniverse" target="_blank">                                
                                    <img :src="base_url+('/assets/images/youniverse/download-playstore.png')" class="wow  animate__fadeInUp animate__animated" data-wow-delay="2s" alt="" width="" height="" style="visibility: visible; animation-delay: 1s; animation-name: fadeInUp;"> 
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <!--section.about-->
            <section id="what" class="section section--about relative">
                <div class="section-main w-full">
                    <div class="container max-w-screen-md mx-auto pt-20 pb-16 px-4 flex flex-col items-center justify-center text-center">
                        <div class="section-body md:flex md:flex-wrap md:gap-8 md:text-left">
                            <img :src="base_url+('/assets/images/youniverse/homepage/about.png')" class="object-contain wow  animate__fadeInUp animate__animated md:flex-1" data-wow-delay="1s" alt="headline" width="630" height="112" style="visibility: visible; animation-delay: 1s; animation-name: fadeInUp;"> 
                            <div class="md:flex-1">
                                <h1 class="section-title section--about-title text-4xl font-medium font-made mb-8 xl:text-7xl xl:tracking-widest leading-tight"><small class="block text-lg font-bold mt-4 xl:text-4xl xl:tracking-wider font-frank wow  animate__fadeInUp animate__animated" data-wow-delay="1.2s" style="visibility: visible; animation-delay: 1.2s; animation-name: fadeInUp;">What is <span>Youniverse?</span></small></h1>
                                <p class="section--about-caption mb-12 wow  animate__fadeInUp animate__animated text-black md:font-14" data-wow-delay="1.4s" style="visibility: visible; animation-delay: 1.4s; animation-name: fadeInUp;">
                                    Youniverse adalah platform komunitas lifestyle terlengkap di Indonesia melingkupi: Kecantikan & Mode, Otomotif, Olahraga, K-pop, Parenting, Fotografi, Saham & Keuangan, dan banyak lagi! 
                                    <br><br>
                                    Youniverse juga siap memberikan dukungan kepada komunitasmu dalam bentuk dukungan event, liputan media, produksi profil video komunitas, dan lainnya!”
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <!--section.get-->
            <section id="get" class="section section--get relative">
                <div class="section-main w-full">
                    <div class="container max-w-screen-md mx-auto py-16 px-4 flex flex-col items-center justify-center text-center">
                        <div class="section-body">
                            <h1 class="section-title section--get-title font-medium font-made mb-8 xl:text-7xl xl:tracking-widest leading-tight"><small class="block text-lg font-bold mt-4 xl:text-4xl xl:tracking-wider font-frank wow  animate__fadeInUp animate__animated" data-wow-delay="1.2s" style="visibility: visible; animation-delay: 1.2s; animation-name: fadeInUp;">What You Get</small></h1>
                            <div class="md:flex md:items-center md:gap-4 md:flex-row-reverse">
                                <img :src="base_url+('/assets/images/youniverse/homepage/get-1.png')" class="object-contain md:flex-1 wow  animate__fadeInUp animate__animated" data-wow-delay="1s" alt="headline" width="630" height="112" style="visibility: visible; animation-delay: 1s; animation-name: fadeInUp;">
                                <div class="md:flex-1 ">
                                    <h1 class="section-title section--get-subtitle text-left text-3xl font-medium font-made mb-4 xl:tracking-widest leading-tight mt-8">Sebagai Komunitas</h1>
                                    <ul class="text-left section-list">
                                        <li class="section-list-item">Support untuk kegiatan komunitas dalam berbagai bentuk seperti: hadiah doorprize, goodie bag, tiket/voucher, dan lainnya</li>
                                        <li class="section-list-item">Promosi dalam bentuk media coverage di berbagai aset media KapanLagi Youniverse</li>
                                        <li class="section-list-item">Pembuatan profil video komunitas secara gratis</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="my-8 md:flex md:items-center md:gap-4 md:flex-row-reverse pt-4">
                                <img :src="base_url+('/assets/images/youniverse/homepage/get-2.png')" class="object-contain md:flex-1 wow  animate__fadeInUp animate__animated" data-wow-delay="1s" alt="headline" width="630" height="112" style="visibility: visible; animation-delay: 1s; animation-name: fadeInUp;">
                                <div class="md:flex-1 ">
                                    <h1 class="section-title section--get-subtitle text-left text-3xl font-medium font-made mb-4 xl:tracking-widest leading-tight mt-8">Sebagai Member</h1>
                                    <ul class="text-left section-list">
                                        <li class="section-list-item">Menjalin relasi dengan ratusan bahkan ribuan teman baru dengan minat yang sama</li>
                                        <li class="section-list-item">Mengikuti aktivitas menarik dengan hadiah berlimpah</li>
                                        <li class="section-list-item">Mendapat undangan eksklusif seperti workshop, seminar, & gathering yang sesuai dengan minat & hobi</li>
                                    </ul>   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <!--section.offer-->
            <section id="offer" class="section section--offer relative">
                <div class="section-main w-full">
                    <div class="container bg-cream max-w-screen-md mx-auto py-16 px-4 flex flex-col items-center justify-center text-center">
                        <div class="section-body">
                            <h1 class="section-title section--offer-title font-medium font-made mb-8 xl:text-7xl xl:tracking-widest leading-tight"><small class="block text-lg font-bold mt-4 xl:text-4xl xl:tracking-wider font-frank wow  animate__fadeInUp animate__animated" data-wow-delay="1.2s" style="visibility: visible; animation-delay: 1.2s; animation-name: fadeInUp;">What We Offer</small></h1>
                            <div class="section-list flex justify-center items-center flex-wrap gap-4">
                                <div class="section-list-item">
                                    <img :src="base_url+('/assets/images/youniverse/homepage/offer-1.png')" alt="image">
                                    <p>Beragam Aktivitas Menarik</p>
                                </div>
                                <div class="section-list-item">
                                    <img :src="base_url+('/assets/images/youniverse/homepage/offer-2.png')" alt="image">
                                    <p>Beragam Hadiah Menarik</p>
                                </div>
                                <div class="section-list-item">
                                    <img :src="base_url+('/assets/images/youniverse/homepage/offer-3.png')" alt="image">
                                    <p>Media Promosi Bagi Komunitas</p>
                                </div>
                                <div class="section-list-item">
                                    <img :src="base_url+('/assets/images/youniverse/homepage/offer-4.png')" alt="image">
                                    <p>Tampilan dan Fitur Terbaru</p>
                                </div>
                                <div class="section-list-item">
                                    <img :src="base_url+('/assets/images/youniverse/homepage/offer-5.png')" alt="image">
                                    <p>Wajah Baru Bagi Komunitas</p>
                                </div>
                              </div>
                        </div>
                    </div>
                </div>
            </section>

            <!-- <link href="mix('assets/css/youniverse/homepage/comm.css')" rel="stylesheet"> -->
            <!--section.community-->
            <section id="community" class="section section--community section--community-new text-white relative">
                <div class="section-main w-full">
                    <div class="container max-w-screen-md mx-auto py-20 px-4">
                        <div class="section-body">
                            <h1 class="section-title section--community-title text-4xl font-medium font-made mb-8 xl:text-7xl xl:tracking-widest leading-tight"><small class="block text-lg font-bold mt-4 xl:text-4xl xl:tracking-wider font-frank wow  animate__fadeInUp animate__animated" data-wow-delay="1.2s" style="visibility: visible; animation-delay: 1.2s; animation-name: fadeInUp;">Youniverse <span>Community</span></small></h1>
                            <div id="swipper" class="swiper section--community-swiper -mx-4 px-4" style="--swiper-theme-color: var(--color-secondary); --swiper-pagination-bullet-inactive-color: var(--color-secondary);">
                                <div class="swiper-wrapper">

                                    <!--new-->
                                    <div :class="room.bg_dashboard" class="swiper-slide wow animate__animated animate__fadeInUp" v-for="(room, index) in dataRoom" style="width: 315.667px; margin-right: 16px;">
                                        <a href="#" class="item block relative">
                                            <div class="item item--community rounded-3xl">
                                                <div class="item-figure">
                                                    <figure class="rounded-3xl aspect-[158/93] overflow-hidden">
                                                        <img class="object-cover w-full h-full" :src="room.cover_url" width="158" height="93" alt="" onerror="this.onerror=null;this.src='https://place-hold.it/158x93';">
                                                    </figure>
                                                    <figure class="rounded-full overflow-hidden mx-auto -mt-16 relative" style="width: 95px; height: 95px">
                                                        <img class="object-cover w-full h-full" :src="room.logo_url" width="92" height="92" alt="" onerror="this.onerror=null;this.src='https://place-hold.it/92x92';">
                                                    </figure>
                                                </div>
                                                <div class="item-desc text-center">
                                                    <span class="item-desc-follower block text-xs font-medium mb-3 mb-2 mt-6">{{ room.title }}</span>
                                                    <h2 class="item-desc-title font-bold text-sm mb-2">{{ room.followers }} Followers</h2>
                                                    <a :href="base_url+('/room/')+room.id+('/')+room.slug" :class="room.btn_dashboard" class="btn font-bold text-sm py-2 px-8 rounded-3xl text-white w-max mx-auto mt-6">Daftar</a>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <!--end of new-->

                                    <div class="swiper-slide swiper-slide--klkorea wow animate__animated animate__fadeInUp">
                                        <a href="#" class="item block relative">
                                            <figure class="item-fig block relative overflow-hidden">
                                                <img :src="base_url+('/assets/images/youniverse/homepage/kl-korea.png')" class="object-cover w-full h-full" alt="image" width="207" height="160">
                                            </figure>
                                        </a>
                                        <div class="item-desc pt-4">
                                                <span class="font-semibold">Kapanlagi Korea</span>
                                                <p>Mau ketemu teman-teman dengan hobi yang sama atau bahkan punya idola yang sama? Bisa banget lho! 
                                                    Komunitas KapanLagi Korea bisa jadi tempat buat kamu ngobrolin idola favorit, 
                                                    drama Korea favorit sampai belajar Bahasa Korea. Dapatkan juga informasi terbaru seputar Korea, 
                                                    fun quiz, dan berbagai giveaway menarik! Let's be the positive fans!</p>
                                        </div>
                                        <a href="#" data-modal="youniversecommunity" class="text-center font-bold block mt-4">Read more</a>
                                    </div>
                                    <div class="swiper-slide swiper-slide--fimelahood wow animate__animated animate__fadeInUp">
                                        <a href="#" class="item block relative">
                                            <figure class="item-fig block relative overflow-hidden">
                                                <img :src="base_url+('/assets/images/youniverse/homepage/fimelahood.png')" class="object-cover w-full h-full" alt="image" width="207" height="160">
                                            </figure>
                                        </a>
                                        <div class="item-desc pt-4">
                                                <span class="font-semibold">Fimelahood</span>
                                                <p>Komunitas Pembaca Fimela atau yang biasa disebut Fimelahood cocok banget buat kamu yang tertarik dengan 
                                                    serba-serba tentang perempuan mulai dari beauty, parenting, lifestyle dan lainnya. 
                                                    Selain bisa update seputar perempuan dan dapat banyak teman baru dari berbagai daerah, 
                                                    banyak kegiatan seru yang bisa diikuti jika tergabung menjadi Fimelahood, mulai dari Fimela Review, 
                                                    Fimelahood Getting Intimate, giveaway dengan berbagai hadiah menarik, dan banyak lagi lainnya.</p>
                                        </div>
                                        <a href="#" data-modal="youniversecommunity" class="text-center font-bold block mt-4">Read more</a>
                                    </div>
                                    <div class="swiper-slide swiper-slide--cekfakta wow animate__animated animate__fadeInUp">
                                        <a href="#" class="item block relative">
                                            <figure class="item-fig block relative overflow-hidden">
                                                <img :src="base_url+('/assets/images/youniverse/homepage/cek-fakta.png')" class="object-cover w-full h-full" alt="image" width="207" height="160">
                                            </figure>
                                        </a>
                                        <div class="item-desc pt-4">
                                                <span class="font-semibold">Cek Fakta</span>
                                                <p>Komunitas pemberantas hoaks dimana kamu bisa membangun relasi & belajar 
                                                    dengan teman-teman dengan berbagai latar belakang melalui 
                                                    forum diskusi interaktif & dapatkan kesempatan hadir dalam acara 
                                                    eksklusif dengan tokoh terkemuka.</p>
                                        </div>
                                        <a href="#" data-modal="youniversecommunity" class="text-center font-bold block mt-4">Read more</a>
                                    </div>
                                    <div class="swiper-slide swiper-slide--kldangdut wow animate__animated animate__fadeInUp">
                                        <a href="#" class="item block relative">
                                            <figure class="item-fig block relative overflow-hidden">
                                                <img :src="base_url+('/assets/images/youniverse/homepage/dangdut.png')" class="object-cover w-full h-full" alt="image" width="207" height="160">
                                            </figure>
                                        </a>
                                        <div class="item-desc pt-4">
                                                <span class="font-semibold">Kapanlagi Dangdut</span>
                                                <p>Komunitas pecinta musik dangdut yang terus mendukung idola untuk berkarya. 
                                                    Dengan bergabung di KapanLagi Dangdut, kamu bisa berkumpul dengan pecinta dangdut Indonesia, 
                                                    dapat update berita dangdut tanah air terbaru, dan kesempatan mengikuti acara-acara dangdut paling seru.</p>
                                            
                                        </div>
                                        <a href="#" data-modal="youniversecommunity" class="text-center font-bold block mt-4">Read more</a>
                                    </div>
                                    <div class="swiper-slide swiper-slide--ots wow animate__animated animate__fadeInUp border-2-black flex items-center justify-center" v-bind:style="{ backgroundImage: 'url('+base_url+('/assets/images/youniverse/homepage/bg-more.png')+')' }" style="background-repeat: no-repeat; background-size: cover; background-position: center;">
                                        <div class="item item--community more-com">
                                            <a :href="base_url+('/room')" class="btn-com relative"><span class="text-primary font-made border-2-black p-4 rounded-xl font-xl" style="background: #F7DA77;">See More<br>Community</span> </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="swiper-pagination static pt-8"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <!--section.join-->
            <section id="join" class="section section--join relative">
                <div class="section-main w-full">
                    <div class="container max-w-screen-md mx-auto py-16 px-4 flex flex-col items-center justify-center text-center">
                        <div class="section-body md:flex md:items-center md:gap-4">
                            <img :src="base_url+('/assets/images/youniverse/homepage/hero-new.png')" class="object-contain md:flex-1 wow  animate__fadeInUp animate__animated" data-wow-delay="1s" alt="headline" width="630" height="112" style="visibility: visible; animation-delay: 1s; animation-name: fadeInUp;"> 
                            <div class="md:flex-1 ">
                                <div>
                                    <h1 class="section-title section--join-title font-medium font-made mb-8 xl:tracking-widest leading-tight"><small class="block text-lg font-bold mt-4 xl:tracking-wider font-frank wow  animate__fadeInUp animate__animated" data-wow-delay="1.2s" style="visibility: visible; animation-delay: 1.2s; animation-name: fadeInUp;">Tambah Teman Baru</small></h1>
                                    <p class="section--join-caption mb-4 wow  animate__fadeInUp animate__animated text-black" data-wow-delay="1.4s" style="visibility: visible; animation-delay: 1.4s; animation-name: fadeInUp;">Dan ikuti kegiatan seru sesuai minatmu</p>
                                    <!-- <a href="/community" class="btn btn--primary bg-primary text-white block max-w-xs mx-auto my-4 py-4 px-6 lg:px-12 hover:bg-primary-dark font-semibold transition wow  animate__fadeIn animate__animated" data-member='bot' data-wow-delay="1.4s" style="visibility: visible; animation-delay: 1.4s; animation-name: fadeIn;">Daftar sebagai Member</a> -->
                                    <a href="javascript:void(0)" @click="popupDownloadApp('open')" class="btn btn--primary bg-primary text-white block max-w-xs mx-auto my-4 py-4 px-6 lg:px-12 hover:bg-primary-dark font-semibold transition wow  animate__fadeIn animate__animated" data-member='bot' data-wow-delay="1.4s" style="visibility: visible; animation-delay: 1.4s; animation-name: fadeIn;">Daftar sebagai Member</a>
                                </div>
                                <div>
                                    <h1 class="section-title section--join-title font-medium font-made mb-8 xl:tracking-widest leading-tight"><small class="block text-lg font-bold mt-4 xl:tracking-wider font-frank wow  animate__fadeInUp animate__animated" data-wow-delay="1.2s" style="visibility: visible; animation-delay: 1.2s; animation-name: fadeInUp;">Kembangkan Komunitasmu</small></h1>
                                    <p class="section--join-caption mb-4 wow  animate__fadeInUp animate__animated text-black" data-wow-delay="1.4s" style="visibility: visible; animation-delay: 1.4s; animation-name: fadeInUp;">Dengan bergabung di Youniverse</p>
                                    <!-- <a href="https://bit.ly/3RLIHB8" class="btn btn--secondary bg-secondary text-white block max-w-xs mx-auto my-4 py-4 px-6 lg:px-12 hover:bg-secondary-dark font-semibold transition wow  animate__fadeIn animate__animated" data-comm='bot' data-wow-delay="1.4s" style="visibility: visible; animation-delay: 1.4s; animation-name: fadeIn;">Daftar sebagai Komunitas</a> -->
                                    <a href="javascript:void(0)" @click="popupDownloadApp('open')" class="btn btn--secondary bg-secondary text-white block max-w-xs mx-auto my-4 py-4 px-6 lg:px-12 hover:bg-secondary-dark font-semibold transition wow  animate__fadeIn animate__animated" data-comm='bot' data-wow-delay="1.4s" style="visibility: visible; animation-delay: 1.4s; animation-name: fadeIn;">Daftar sebagai Komunitas</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </div>
    </main>

    <!--footer-->
    <footer class="footer">
        <div class="container max-w-screen-md mx-auto pt-11 pb-6 px-4">
            <div class="footer-body flex flex-col text-center justify-center items-center gap-4">
                <a class="footer-logo inline-block" href="/">
                  <img class="object-contain" :src="base_url+('/assets/images/youniverse/youniverse.png')" width="100" height="50">
                </a>
                <div class="flex items-center justify-center">
                    <a target="_blank" href="mailto:komunitas.kapanlagi@gmail.com"><img :src="base_url+('/assets/images/youniverse/homepage/mail.png')" alt="image"></a>
                </div>
                <p class="text-center">© All rights reserved. Kapanlagi Youniverse</p>
            </div>
        </div>
    </footer>

    <div class="modal fixed inset-0 w-full h-full overflow-y-auto z-40 bg-black/80 text-black" data-modal-open="youniversecommunity">
        <div class="modal-inner flex items-center justify-center min-h-full p-4 swiper">
            <div class="swiper-wrapper">
                <div class="swiper-slide">
                    <div class="modal-inner-main relative max-w-screen-lg mx-auto rounded-xl p-8 lg:px-8 w-full">
                        <a href="#" class="data-modal-close absolute top-4 right-4">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.5625 14.6157L1.4375 1.45831M14.5625 1.45831L1.4375 14.6157" stroke="#E63C7F" stroke-width="2.625" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </a>
                        <div class="modal-inner-main-body text-center" style="--swiper-theme-color: var(--color-black-gray); --swiper-navigation-size: 22px">
                            <h1 class="modal-inner-main-title text-2xl lg:text-3xl mb-2 font-made">Kapanlagi Korea</h1>
                            <p>Mau ketemu teman-teman dengan hobi yang sama atau bahkan punya idola yang sama? Bisa banget lho! 
                                Komunitas KapanLagi Korea bisa jadi tempat buat kamu ngobrolin idola favorit, 
                                drama Korea favorit sampai belajar Bahasa Korea. Dapatkan juga informasi terbaru seputar Korea, 
                                fun quiz, dan berbagai giveaway menarik! Let's be the positive fans!</p>
                        </div>
                    </div>
                </div>
                <div class="swiper-slide">
                    <div class="modal-inner-main relative max-w-screen-lg mx-auto rounded-xl p-8 lg:px-8 w-full">
                        <a href="#" class="data-modal-close absolute top-4 right-4">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.5625 14.6157L1.4375 1.45831M14.5625 1.45831L1.4375 14.6157" stroke="#E63C7F" stroke-width="2.625" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </a>
                        <div class="modal-inner-main-body text-center" style="--swiper-theme-color: var(--color-black-gray); --swiper-navigation-size: 22px">
                            <h1 class="modal-inner-main-title text-2xl lg:text-3xl mb-2 font-made">Fimelahood</h1>
                            <p>Komunitas Pembaca Fimela atau yang biasa disebut Fimelahood cocok banget buat kamu yang tertarik dengan 
                                serba-serba tentang perempuan mulai dari beauty, parenting, lifestyle dan lainnya. 
                                Selain bisa update seputar perempuan dan dapat banyak teman baru dari berbagai daerah, 
                                banyak kegiatan seru yang bisa diikuti jika tergabung menjadi Fimelahood, mulai dari Fimela Review, 
                                Fimelahood Getting Intimate, giveaway dengan berbagai hadiah menarik, dan banyak lagi lainnya.</p>
                        </div>
                    </div>
                </div>
                <div class="swiper-slide">
                    <div class="modal-inner-main relative max-w-screen-lg mx-auto rounded-xl p-8 lg:px-8 w-full">
                        <a href="#" class="data-modal-close absolute top-4 right-4">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.5625 14.6157L1.4375 1.45831M14.5625 1.45831L1.4375 14.6157" stroke="#E63C7F" stroke-width="2.625" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </a>
                        <div class="modal-inner-main-body text-center" style="--swiper-theme-color: var(--color-black-gray); --swiper-navigation-size: 22px">
                            <h1 class="modal-inner-main-title text-2xl lg:text-3xl mb-2 font-made">Cek Fakta</h1>
                            <p>Komunitas pemberantas hoaks dimana kamu bisa membangun relasi & belajar 
                                dengan teman-teman dengan berbagai latar belakang melalui 
                                forum diskusi interaktif & dapatkan kesempatan hadir dalam acara 
                                eksklusif dengan tokoh terkemuka.</p>
                        </div>
                    </div>
                </div>
                <div class="swiper-slide">
                    <div class="modal-inner-main relative max-w-screen-lg mx-auto rounded-xl p-8 lg:px-8 w-full">
                        <a href="#" class="data-modal-close absolute top-4 right-4">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.5625 14.6157L1.4375 1.45831M14.5625 1.45831L1.4375 14.6157" stroke="#E63C7F" stroke-width="2.625" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </a>
                        <div class="modal-inner-main-body text-center" style="--swiper-theme-color: var(--color-black-gray); --swiper-navigation-size: 22px">
                            <h1 class="modal-inner-main-title text-2xl lg:text-3xl mb-2 font-made">Kapanlagi Dangdut</h1>
                            <p>Komunitas pecinta musik dangdut yang terus mendukung idola untuk berkarya. 
                                Dengan bergabung di KapanLagi Dangdut, kamu bisa berkumpul dengan pecinta dangdut Indonesia, 
                                dapat update berita dangdut tanah air terbaru, dan kesempatan mengikuti acara-acara dangdut paling seru.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="popup-download-app hidden" @click="popupDownloadApp('close')">
        <div class="popup-download-app_body">
            <a href="" target="_blank" @click="pushTracker()">
                <img :src="base_url+('/assets/images/youniverse/download-app.jpg')">
            </a>
        </div>
    </div>

    <!-- Trigger Notification Popup -->
    <div class="popup-link hidden">
        <div class="popup-link_body" v-bind:style="{ backgroundImage: 'url('+base_url+('/assets/images/youniverse/homepage/bg-trigger-notif.png')+')' }">
            <i @click="closePopup()" class="fa-solid fa-xmark white-color close-popup"></i>
            <img class="icon-popup" :src="base_url+('/assets/images/youniverse/homepage/icon-trigger-notif.png')">
            <p class="text-popup">Yuk cek point mu</p>
            <button @click="closePopup(true)" class="btn-click">Disini</button>
        </div>
    </div>

    <!-- Trigger City Popup -->
    <div class="popup-link-city hidden">
        <div class="popup-link-city_body">
            <p class="popup-city-txt-header">Kamu belum isi kota nih!</p>
            <p class="popup-city-txt-desc">Kamu tinggal dimana?</p>
            <select class="form-control-city" name="city" id="input-city">
                <option value="">Kota</option>
            </select>
            <button class="btn-submit-city btn-disabled" style="margin-top: 12px;" @click="closePopupCity()">Kirim</button>
        </div>
    </div>

  </div>
</template>

<script>

import Api from '@/services/Api'
import { global_function } from '@/global_function.js'
import config from '@/config/config'
import '@/css/homepage/utilities.css'
import '@/css/homepage/header.css'
import '@/css/homepage/components.css'
import '@/css/homepage/all.css'
import '@/css/homepage/comm.css'
import '@/css/homepage/popup_city.css'
import axios from 'axios'

export default {
    mixins: [global_function],
    data () {
        return {
            menu:false,
            base_url:config.get("base_url"),
            dataRoom: [],
        }
    },
    created: function() {
    },
    destroyed () 
    {
    },
    updated () 
    {
    },
    mounted () {
        Api.get_login_status(function(response){ // get articles from api
            if (response.data.login != '1') { // !login
                $('.nav-login').css("display","none");
                $('.btn-dashboard-mobile').css("display","none");
            } else {
                $('.btn-login-youniverse').css("display","none");
                $('.btn-login-mobile').css("display","none");
                $('.login').css('display', 'block');
                $('.notif-desktop').css('display', 'block');
                $('.notif-mobile').css('display', 'block');
                $('.login').attr('href', window.location.origin + '/dashboard');
                $('.icon-user').attr('src', response.data.user.profile_photo_url); 
                $('.nav-login').css("display","unset");
            }
        });

        Api.get_count_notification(function(response){
            if (response.data.count > 0) {
                var rows_mobile = '<a href="'+window.location.origin+'/dashboard/notification/'+response.data.user_id+'" data-toggle="menuspy" class="nav-item-link font-made block py-4 px-2 mr-2"><div class="icon-badge-group"><div class="icon-badge-container"><i class="fa-regular fa-lg fa-bell icon-badge-icon"></i><div class="icon-badge">'+response.data.count+'</div></div></div></a>';
                var rows_desktop = '<a href="'+window.location.origin+'/dashboard/notification/'+response.data.user_id+'" data-toggle="menuspy" class="nav-item-link font-made block py-4 px-2"><div class="icon-badge-group"><div class="icon-badge-container"><i class="fa-regular fa-lg fa-bell icon-badge-icon"></i><div class="icon-badge">'+response.data.count+'</div></div></div></a>';
            } else {
                var rows_mobile = '<a href="'+window.location.origin+'/dashboard/notification/'+response.data.user_id+'" data-toggle="menuspy" class="nav-item-link font-made block py-4 px-2 mr-2"><i class="fa-regular fa-2x fa-bell"></i></a>';
                var rows_desktop = '<a href="'+window.location.origin+'/dashboard/notification/'+response.data.user_id+'" data-toggle="menuspy" class="nav-item-link font-made block py-4 px-2"><i class="fa-regular fa-lg fa-bell"></i></a>';
            }
            $('.notif-mobile').append(rows_mobile);
            $('.notif-desktop').append(rows_desktop);
        });

        Api.get_session_trigger_notification_popup(function(response){
            if (response.data.session == true) {
                $('body').css('overflow', 'hidden');
                $('.popup-link').removeClass('hidden');
            }
        });

        Api.get_session_trigger_city_popup(function(response){
            if (response.data.session == true) {
                response.data.cities_name.forEach(element => {
                    $('#input-city').append('<option value="'+element+'">'+element+'</option>');
                });

                $('body').css('overflow', 'hidden');
                $('.popup-link-city').removeClass('hidden');
            }
        });

        Api.get_user_agent(function(response) {
            if (response.data.is_ios) {
                $('.popup-download-app a').attr('href', 'https://apps.apple.com/app/apple-store/id6451060380?pt=117963666&ct=websiteyouniverse_awareness_USP_youniverse&mt=8')
            } else {
                $('.popup-download-app a').attr('href', 'https://play.google.com/store/apps/details?id=com.kly.youniverse&referrer=utm_source%3Dwebsiteyouniverse%26utm_campaign%3Dawareness_USP_youniverse')
            }
        });

        this.readRoom();
    },
    methods: {
        closePopup: function (isPoint)
        {
            var url = this.base_url;
            Api.forget_session_trigger_notification_popup(function(response){
                if (response.data.session == null) {
                    if (isPoint == true && isPoint != undefined) {
                        window.location.href = url+'/dashboard/point';
                    } else {
                        $('body').css('overflow', '');
                        $('.popup-link').addClass('hidden');
                    }
                }
            });
        },
        closePopupCity: function ()
        {
            var city = $('#input-city').find(":selected").val();
            var api_url = '/api/forget-session-trigger-city-popup/';

            axios.post(this.base_url + api_url, {
                city: city
            }).then(
                function(rs){
                    if (rs.session == null) {
                        $('body').css('overflow', '');
                        $('.popup-link-city').addClass('hidden');
                    } else if (rs.session == 'session_timeout') {
                        alert('Session kamu habis! Silahkan login terlebih dahulu.');
                        window.location.href = this.base_url + '/login';
                    }
            }).catch(
                function(e){ console.log(["error",e])
            });
        },
        readRoom()
        {
            axios.get('/api/get-gateway-room-dashboard')
                .then(response => {
                    this.dataRoom = response.data.room;

                    const swiperCommunity = new Swiper(".section--community-swiper", {
                        slidesPerView: 1.2,
                        spaceBetween: 16,
                        pagination: {
                            el: ".swiper-pagination",
                        },
                        breakpoints: {
                            768: {
                            slidesPerView: 2.3,
                            spaceBetween: 20,
                            },
                        },
                    });
                });
        },
        popupDownloadApp: function(action)
        {
            if (action == 'close') {
                $('.popup-download-app').addClass('hidden');
            } else {
                $('.popup-download-app').removeClass('hidden');
            }
        },
        pushTracker: function()
        {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'click',
                feature_name: 'force download apps',
                feature_location: 'Homepage',
                feature_position: 'dialog download apps'
            });
        }
    },
    watch: {
      
    }
}
</script>