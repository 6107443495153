export default {
    get(key)
    {
        let version = document.head.querySelector('meta[name="version"]').content;

        var config = {
            version:  version,
            stored: {
                // before login
                0: {
                    token: `sso-${version}-token-bl`,
                    last_update: `sso-${version}-last-bl`,
                    last_update_popular: `sso-${version}-last-popular-bl`,
                    last_update_tag: `sso-${version}-last-tag-bl`,
                    last_update_category: `sso-${version}-last-category-bl`,
                    list_articles: `sso-${version}-articles-bl`,
                    list_articles_popular: `sso-${version}-articles-popular-bl`,
                    list_detail_articles: `sso-${version}-detail-articles-bl`,
                    list_tag_articles: `sso-${version}-tag-articles-bl`,
                    list_category_articles: `sso-${version}-category-articles-bl`,
                },
                // after login
                1: {
                    token: `sso-${version}-token-al`,
                    last_update: `sso-${version}-last-al`,
                    last_update_popular: `sso-${version}-last-popular-al`,
                    last_update_tag: `sso-${version}-last-tag-al`,
                    last_update_category: `sso-${version}-last-category-al`,
                    list_articles: `sso-${version}-articles-al`,
                    list_articles_popular: `sso-${version}-articles-popular-al`,
                    list_detail_articles: `sso-${version}-detail-articles-al`,
                    list_tag_articles: `sso-${version}-tag-articles-al`,
                    list_category_articles: `sso-${version}-category-articles-al`,
                }
            },
            environtment: document.head.querySelector('meta[name="environment"]').content, // production | devel | local
            base_url: document.head.querySelector('meta[name="base"]').content,
            url_description: document.head.querySelector('meta[name="description"]').content,
            csrf_token: document.head.querySelector('meta[name="csrf-token"]').content,
            storage_link: document.head.querySelector('meta[name="storage_link"]').content,
            api: {
                url: 'https://www.one.co.id/',
                client_id: '21',
                client_secret: 'f28d2wUsxRu1MRsc0GouGhLxPgwDXKLcVKFT1rUd',
                grant_type: 'client_credentials',
                before_login: {
                    client_id: '22',
                    client_secret: '419Hple7jTE9NGt5gNvgyWLQiTxKhsAZrOsipw5j',
                }
            },
            session: {
                homepage_current_page: `sso-${version}-homepage_current_page`,
            }
        };

        // if(config.environtment == 'production')
        // {
        //     config.base_url = 'https://www.liputan6.com/';
        //     config.api.url = 'https://www.one.co.id/';
        //     config.api.client_secret = 'f28d2wUsxRu1MRsc0GouGhLxPgwDXKLcVKFT1rUd';
        // }

        // if(config.environtment == 'devel')
        // {
        //     config.base_url = 'http://staging.liputan6.id/';
        // }

        if(key == undefined || key == null || key == ""){
            return config;
        }

        var arr_key = key.split(".");
        var rs_config = config;
        arr_key.forEach(function(k){
            rs_config = rs_config[k];
        });

        return rs_config;
    }
}